import { APP_NAME } from 'app/config';
import React from 'react';
import MetaTags from 'react-meta-tags';
import { Container, Card, CardBody, Col, Row } from 'reactstrap';

const TermsPage = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>免責及私隱條款 | {APP_NAME}</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="pt-3">
                    <div className="row justify-content-center">
                      <div className="col-xl-8">
                        <div>
                          <div className="text-center">
                            <h4>免責及私隱條款</h4>
                          </div>

                          <hr />

                          <div className="mt-4">
                            <div className="text-muted font-size-14">
                              <h5 className="mb-3">
                                參加心情盆JOY之活動或使用心情盆JOY提供之服務或練習的免責聲明
                              </h5>

                              <p className="mb-4">
                                在使用心情盆JOY服務、參加心情盆JOY活動時，任何人士應根據個人健康及體
                                力狀況，量力而為；並須遵守心情盆JOY相關指引及職員／工作人員的指導。心
                                情盆JOY不須對因不遵從有關指引／指導或人力所無法避免之原因而造成之任何
                                傷害或損害負責。參與者應根據個人健康及精神狀況，決定是否適合參與相關
                                活動或練習或使用相關服務。如有疑問，請於進行有關活動或練習或使用相關
                                服務前向家庭醫生或相關專業人士查詢。
                              </p>

                              <p>
                                這應用程式的內容及建議不能替代專業的醫療建議及服務。
                              </p>
                              <h5 className="mb-3">應用程式免責條款</h5>
                              <p className="mb-4">
                                心情盆JOY所載的資料只供參考之用。雖然心情盆JOY已盡力確保本應用程式的
                                資料準確，但不會保證或擔保該等資料均準確無誤。心情盆JOY不會對任何錯誤
                                或遺漏承擔法律責任。如使用者由心情盆JOY應用程式連結至其他機構所提供的
                                網頁，必須注意該等網頁是由網頁所屬機構編製及提供。心情盆JOY不會對連結
                                本應用程式的超文本的內容負責，及不會對因使用該超文本引致的損失或損害
                                承擔責任。
                              </p>
                              <p className="mb-4">
                                當用戶一旦使用本應用程式，即表示你無條件接受上述所載的免責條款以及任
                                何心情盆JOY不時在無須預先通知你的情況下而對上述條款所作出的修改及/或
                                修訂。請定期瀏覽此應用程式查閱任何修改及/或修訂。
                              </p>

                              <h5 className="mb-3">收集個人資料須知</h5>

                              <p className="mb-4">
                                根據個人資料（私隱）條例，心情盆JOY採用以下政策，以確保應用程式使用者
                                之個人資料得到保密。提供有關個人資料予本會前，請先細閱本文。
                                收集個人資料的目的：心情盆JOY將根據你所提供的個人資料，處理你提出的服
                                務申請。提供個人資料是自願性的。若你未能提供足夠個人資料，心情盆JOY可
                                能無法處理你的申請或向你提供服務。請確保你所提供之資料正確無誤及通知
                                本會任何有關資料改動。
                              </p>

                              <h5 className="mb-3">獲悉資料人士的類別</h5>
                              <p className="mb-4">
                                獲悉個人資料的人士，限於心情盆JOY在工作上有關的職員，本計劃職員在需要
                                時亦只會向下列有關方面披露該等資料：
                              </p>
                              <p className="mb-4">
                                1.其他涉及評估你的服務申請或向你提供服務的有關方面，例如政府部門、非
                                政府機構、公用事業公司；
                              </p>
                              <p className="mb-4">
                                2.你同意向其披露資料的人士、機構；或
                              </p>
                              <p className="mb-4">
                                3.由法律授權或定向其披露資料的有關方面。
                              </p>
                              <h5 className="mb-3">查閱及改正個人資料</h5>
                              <p className="mb-4">
                                除個人資料 ( 私隱 )
                                條例規定的豁免外，你有權要求查閱及改正本會所存有關你
                                個人資料。你的查閱權利包括在遞交申請表格後，得到有關資料文件複本。
                              </p>
                              <h5 className="mb-3">查詢</h5>

                              <p className="mb-4">
                                如對上述有關個人資料收集方法、查閱及改正個人資料途徑有任何查詢，請聯
                                絡我們。
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TermsPage;
