export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const calEmotionScore = (content: number[]) => {
  if (content && content.length > 0) {
    const s =
      content[0] +
      content[5] +
      content[7] +
      content[10] +
      content[11] +
      content[13] +
      content[17];
    const d =
      content[2] +
      content[4] +
      content[9] +
      content[12] +
      content[15] +
      content[16] +
      content[20];
    const a =
      content[1] +
      content[3] +
      content[6] +
      content[8] +
      content[14] +
      content[18] +
      content[19];
    return [s * 2, d * 2, a * 2];
  }
  return [0, 0, 0];
};
