import Axios from './';
import {
  AdminLoginPayload,
  ListResult,
  Admin,
  AdminPara,
  Filter,
  AdminLoginRes,
} from '../models';

/**
 * * Get Admin List (https://cfsc-dev.appicidea.com/doc/#/Admin/get_v1_admin)
 */

export interface GetAdminListParams extends Filter {
  displayName?: string;
}

export const getAdminList = async (params: GetAdminListParams) => {
  const { data } = await Axios.get<ListResult<Admin>>('/admin', {
    params,
  });
  return data;
};

/**
 * * Create new Admin by Admin (https://cfsc-dev.appicidea.com/doc/#/Admin/post_v1_admin)
 */

export const createNewAdmin = async (para: AdminPara) => {
  const { data } = await Axios.post<Admin>('/admin', para);
  return data;
};

/**
 * * Get current Admin profile (https://cfsc-dev.appicidea.com/doc/#/Admin/get_v1_admin_profile)
 */

export const getCurrAdminProf = async () => {
  const { data } = await Axios.get<Admin>('/admin/profile');
  return data;
};

/**
 * * Update current Admin profile (https://cfsc-dev.appicidea.com/doc/#/Admin/patch_v1_admin_profile)
 */

export const updateCurrAdminProf = async (para: { displayName: string }) => {
  const { data } = await Axios.patch<Admin>('/admin/profile', para);
  return data;
};

/**
 * * Reset current Admin pw (https://cfsc-dev.appicidea.com/doc/#/Admin/post_v1_admin_resetPassword)
 */

export const resetCurrAdminPassword = async (para: { password: string }) => {
  const { data } = await Axios.post<Admin>('/admin/resetPassword', para);
  return data;
};

/**
 * * Get Admin by adminid (https://cfsc-dev.appicidea.com/doc/#/Admin/get_v1_admin__adminid_)
 */

export const getSingleAdmin = async (adminid: string) => {
  const { data } = await Axios.get<Admin>(`/admin/${adminid}`);
  return data;
};

/**
 * * Patch Admin by adminid (https://cfsc-dev.appicidea.com/doc/#/Admin/patch_v1_admin__adminid_)
 */

export const updateAdmin = async (
  adminid: number,
  para: { displayName: string },
) => {
  const { data } = await Axios.patch<string>(`/admin/${adminid}`, para);
  return data;
};

/**
 * * Delete Admin by adminid (https://cfsc-dev.appicidea.com/doc/#/Admin/delete_v1_admin__adminid_)
 */

export const deleteAdmin = async (adminid: number) => {
  const { data } = await Axios.delete<string>(`/admin/${adminid}`);
  return data;
};

export const getProfile = async () => {
  const { data } = await Axios.get<ListResult<Admin>>('/admin/profile');
  return data;
};

/**
 * * Reset one Admin pw (https://cfsc-dev.appicidea.com/doc/#/Admin/post_v1_admin__adminid__resetPassword)
 */

export const resetAdminPassword = async (
  adminid: number,
  para: { password?: string },
) => {
  const { data } = await Axios.post<Admin>(
    `/admin/${adminid}/resetPassword`,
    para,
  );
  return data;
};

/**
 * * Admin login (https://cfsc-dev.appicidea.com/doc/#/Admin/post_v1_admin_login)
 */

export const adminLogin = async (para: AdminLoginPayload) => {
  const { data } = await Axios.post<AdminLoginRes>('/admin/login', para);
  return data;
};

export const logout = async () => {
  const { data } = await Axios.post('/logout');
  return data;
};
