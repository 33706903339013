import { IMAGE_URL } from 'app/config';

type ImageSizeType = 320 | 768 | 1280;

export const getImageURL = (
  imgSrc: string,
  size?: ImageSizeType | 'original',
) => {
  if (imgSrc && imgSrc.includes('https')) {
    return imgSrc;
  } else if (imgSrc) {
    return `${IMAGE_URL}/${size ? size : '320'}/${imgSrc}`;
  }
  return '';
};

export const formatPrice = (price: number) => {
  if (price) {
    const dollarUS = Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return dollarUS.format(price);
  }
  return '-';
};

export const formatTime = (time: string) => {
  if (time) {
    return time.substring(0, 5);
  }
  return '';
};
