import { Filter, ListResult } from 'app/models';
import { TakeDown, TakeDownData } from 'app/models/TakeDown';
import Axios from '.';

/*
 ** Submit Take Down Report ()
 */

export const createTakeDown = async (params: TakeDown) => {
  const { data } = await Axios.post<any>(`/takeDown`, params);
  return data;
};

/*
 ** Submit Take Down Report ()
 */

export interface GetTakeDownListParams extends Filter {}

export const getTakeDownList = async (params: GetTakeDownListParams) => {
  const { data } = await Axios.get<ListResult<TakeDownData>>('/takeDown', {
    params,
  });
  return data;
};

/*
 ** Get one TakeDown by takedownid ()
 */

export const getOneTakeDown = async (takedownid: string | number) => {
  const { data } = await Axios.get<TakeDownData>(`/takeDown/${takedownid}`);
  return data;
};
