import { Redirect } from 'react-router-dom';

import { LogoutPage, LoginPage } from 'app/pages/Authentication';
import { AdminListPage, AdminFormPage } from 'app/pages/AdminPage';
import { UserListPage } from 'app/pages/UserPage';
import { NotFoundPage } from 'app/pages/NotFoundPage/Loadable';
import { ActivityListPage, ActivityFormPage } from 'app/pages/ActivityPage';
import { CaseListPage } from 'app/pages/CasePage';
import { EmotionListPage, EmotionDetailPage } from 'app/pages/EmotionPage';
import { ACTPreDetailPage, ACTPreListPage } from 'app/pages/ACTPrePage';
import { ACTPostDetailPage, ACTPostListPage } from 'app/pages/ACTPostPage';
import { RecordListPage } from 'app/pages/RecordPage';
// import { PlantListPage } from 'app/pages/PlantPage';
import { PostDetailPage, PostListPage } from 'app/pages/ForumPage';
import { GiftListPage, GiftFormPage, GiftRedeemPage } from 'app/pages/GiftPage';
import { RedeemListPage } from 'app/pages/RedeemPage';
import { DictionaryListPage } from 'app/pages/DictionaryPage';
import TermsPage from 'app/pages/Terms';
import { TakeDownDetailPage, TakeDownListPage } from 'app/pages/TakeDownPage';
import { TakeDownPage } from 'app/pages/TakeDownFormPage';

interface Route {
  path: string;
  component: any;
  exact?: boolean;
}
const userRoutes: Route[] = [
  // activity
  { path: '/activities', component: ActivityListPage },
  { path: '/activities/new', component: ActivityFormPage },
  { path: '/activities/edit/:activityid', component: ActivityFormPage },

  // admin
  { path: '/admins', component: AdminListPage },
  { path: '/admins/new', component: AdminFormPage, exact: true },
  { path: '/admins/edit/:adminid', component: AdminFormPage, exact: true },
  {
    path: '/admins/edit/:adminid/:password',
    component: AdminFormPage,
    exact: true,
  },
  { path: '/users', component: UserListPage },
  // Form
  { path: '/cases', component: CaseListPage },
  { path: '/emotion', component: EmotionListPage },
  { path: '/emotion/detail/:formid', component: EmotionDetailPage },
  { path: '/actpre', component: ACTPreListPage },
  { path: '/actpre/detail/:formid', component: ACTPreDetailPage },
  { path: '/actpost', component: ACTPostListPage },
  { path: '/actpost/detail/:formid', component: ACTPostDetailPage },
  // Record
  { path: '/records', component: RecordListPage },
  // Forum
  { path: '/posts', component: PostListPage },
  { path: '/posts/detail/:postid', component: PostDetailPage },
  // Plant
  // { path: '/plants', component: PlantListPage },
  // gift
  { path: '/giftItems', component: GiftListPage },
  { path: '/giftItems/new', component: GiftFormPage },
  { path: '/giftItems/edit/:giftid', component: GiftFormPage },
  { path: '/giftItems/redeem/:giftid', component: GiftRedeemPage },
  // redeem record
  { path: '/redeemRecords', component: RedeemListPage },
  // takedown
  {
    path: '/take-down',
    component: TakeDownListPage,
  },
  {
    path: '/take-down/detail/:takedownid',
    component: TakeDownDetailPage,
  },

  { path: '/dictionary', component: DictionaryListPage },
  { path: '/', component: () => <Redirect to="/activities" /> },
  { path: '/notFound', component: NotFoundPage },
  { path: '*', component: () => <Redirect to="/notFound" /> },
];

const authRoutes: Route[] = [
  { path: '/logout', component: LogoutPage },
  { path: '/login', component: LoginPage },
  // terms
  { path: '/terms', component: TermsPage },
  { path: '/takedown', component: TakeDownPage },
  { path: '/notFound', component: NotFoundPage },
];

export { userRoutes, authRoutes };
